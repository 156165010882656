import React, { useState, useEffect } from 'react'
import { Spinner, Card, CardBody, Row, Col } from 'reactstrap'
import { message } from 'antd'

import QuoteStatesWidget from './partials/widgets/QuoteStatesWidget'
import QuoteOpensWidget from './partials/widgets/QuoteOpensWidget'
import QuoteNotesWidget from './partials/widgets/QuoteNotesWidget'
import QuoteWhatsappClicksWidget from './partials/widgets/QuoteWhatsappClicksWidget'
import ProfileHeader from './partials/ProfileHeader'
import QuoteDetails from './partials/QuoteDetails'

import EditLeadModal from '../Leads/partials/EditLeadModal'
import AddOptionModal from './partials/AddOptionModal'
import QuoteAiDrawer from '../SpringAI/QuoteAiDrawer'

import { deleteProductQuote, getQuote } from './services'
import { handleError } from '../../helpers'

export default function QuoteProfile(props) {
	const [quote, setQuote] = useState(null)	
	const [showEditModal, setShowEditModal] = useState(false)
	const [showQuoteAiDrawer, setShowQuoteAiDrawer] = useState(false)
	const [showAddOptionModal, setShowAddOptionModal] = useState(false)
	const quoteId = props.match.params.id

	useEffect(() => {
		if(quote && quote.id !== parseInt(quoteId)) setQuote(null)
	}, [ quoteId, quote ])

	useEffect(()=>{
		!quote && getQuote(quoteId, { 
			include: 'lead,store,user,states,opens,opensCount,profile',
		})
			.then(res => setQuote(res.data.data))
			.catch(error => handleError(error))
	}, [quote, quoteId])

	const handleDeleteOption = async products => {
		try {
			await products.forEach(async product => {
				await deleteProductQuote(product.pivot_id)
			})
			
			await message.success('Opción eliminada exitosamente')
			setQuote(null)
		} catch (error) {
			message.error('Algo salio mal. Favor comunicarse a soporte@smart4.com.co')
		}
	}

	if(!quote) return <Spinner />

	return (
		<React.Fragment>
			<Card className='quote-preview'>
				<ProfileHeader 
					quote={quote}
					openAiDrawer={() => setShowQuoteAiDrawer(true)}
					openAddOption={() => setShowAddOptionModal(true)}
					reload={() => setQuote(null)}
				/>
				<CardBody>
					<QuoteDetails 
						quote={quote}
						handleEdit={() => setShowEditModal(true)}
						handleDeleteOption={handleDeleteOption}
					/>
				</CardBody>
			</Card>
			<Row className="mb-4 no-print">
				<Col>
					<QuoteStatesWidget quote={quote} />
					<QuoteOpensWidget quote={quote} />
				</Col>
				<Col>
					<QuoteWhatsappClicksWidget quote={quote} />
				</Col>
				<Col>
					<QuoteNotesWidget quote={quote} />
				</Col>
			</Row>

			{ showEditModal && (
				<EditLeadModal
					visible
					onClose={() => setShowEditModal(null)}
					lead={quote.lead}
					reload={() => setQuote(null)}
				/>
			)}
			{ showQuoteAiDrawer && (
				<QuoteAiDrawer 
					visible
					onClose={() => setShowQuoteAiDrawer(false)}
					quote={quote}
				/>
			)}
			{ showAddOptionModal && (
				<AddOptionModal 
					visible
					onClose={() => setShowAddOptionModal(false)}
					reload={() => setQuote(null)}
					quote={quote}
				/>
			)}
		</React.Fragment>
	)
}