import React from 'react'
import { Modal, ModalHeader, ModalBody, Spinner, Button } from 'reactstrap'
import { Divider, Select } from 'antd'

export default function StoresUsersFilterModal({ 
	visible, 
	onClose, 
	storesIds, 
	setStoresIds, 
	usersIds, 
	setUsersIds, 
	stores, 
	users,
	user,
	reload
}) {
	return (
		<Modal isOpen={visible} toggle={()=>onClose(false)}>
			<ModalHeader toggle={()=>onClose(false)}>Filtro de Tiendas y Asesores</ModalHeader>
			<ModalBody>
				<h6>Tiendas</h6>
				{ stores ? (
					<React.Fragment>
						<Select
							mode="multiple"
							allowClear
							style={{ width: '100%' }}
							placeholder="Ver todos las tiendas"
							value={storesIds}
							onChange={value => setStoresIds(value)}
							filterOption={(inputValue, option) => option.name.toLowerCase().includes(inputValue.toLowerCase())}
						>
							{ stores.map(store => <Select.Option key={store.id} value={store.id} name={store.name}>{store.name}</Select.Option>) }
						</Select>
						<Button className="mt-2" color="secundary" size="sm" onClick={()=>setStoresIds([])}>Quitar Todas</Button>
						<Divider type="vertical" />
						<Button className="mt-2" color="secundary" size="sm" onClick={()=>setStoresIds(stores.map(store=>store.id))}>Seleccionar Todas</Button>
					</React.Fragment>
				) : <Spinner size="sm" />}
				<h6 className="mt-3">Asesores</h6>
				{ users ? (
					<React.Fragment>
						<Select
							mode="multiple"
							allowClear
							style={{ width: '100%' }}
							placeholder="Ver todos los asesores"
							value={usersIds}
							onChange={value => setUsersIds(value)}
							disabled={user.role === 'agent'}
							filterOption={(inputValue, option) => option.name.toLowerCase().includes(inputValue.toLowerCase())}
						>
							{ users.map(user => <Select.Option key={user.id} value={user.id} name={user.name}>{user.name}</Select.Option>) }
						</Select>
						<Button className="mt-2" color="secundary" size="sm" onClick={()=>setUsersIds([])}>Quitar Todos</Button>
						<Divider type="vertical" />
						<Button className="mt-2" color="secundary" size="sm" onClick={()=>setUsersIds(users.map(user=>user.id))}>Seleccionar Todos</Button>
					</React.Fragment>
				) : <Spinner size="sm" />}
				<div className="mt-2">
					<Button color="primary" onClick={()=>{ onClose(); reload() }}>Filtrar Reporte</Button>				
				</div>
			</ModalBody>
		</Modal>
	)
}