import React from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import { markAsWon } from '../../Quotes/services'
import { handleError } from '../../../helpers'
import { useSelector } from 'react-redux'

export default function NewQuoteInvoiceModal({ 
	visible, 
	onClose, 
	quote, 
	status, 
	handleSuccess, 
	statusList, 
	selectedStatusIndex 
}) {
	const user = useSelector(state => state.auth.details)
	const { handleSubmit, register, errors, watch } = useForm()

	const onSubmit = values => {
		values.user_id = user.id
		values.quote_id = quote.id
		values.status = status
		values.validation_mobile = quote.lead.mobile

        if(values.validation_relationship === 'lead' && quote.lead.document){            
            values.validation_document = quote.lead.document
		}

		markAsWon(values)
			.then(()=>handleSuccess())
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Asociar Factura con Cotización</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>					
					<Form.Group>
						<Form.Label>Número de Factura <span className="text-primary">*</span></Form.Label>
						<Form.Control
							name="invoice_reference"
							placeholder="S20F1234" 
							ref={register({ required: true })}
						/>
					    { errors.invoice_reference && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
					</Form.Group>
                    <Form.Group>
                        <Form.Label>Quién realizó la compra <span className="text-primary">*</span></Form.Label>
                        <Form.Control
                            as="select"
                            name="validation_relationship"
                            ref={register({ required: true })}
                            defaultValue="lead"
                        >
                            <option value="lead" disabled>Misma persona que cotizó</option>
                            <option value="family">Familiar/Conocido</option>
                            <option value="company">A Nombre de Empresa</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Documento de quien realiza la compra <span className="text-primary">*</span></Form.Label>
                        <Form.Control
                            name="validation_document"
                            ref={register({ required: true })}
                            defaultValue={watch('validation_relationship') === 'lead' ? quote.lead.document : ''}
                            disabled={(watch('validation_relationship') === 'lead' && quote.lead.document) ? true : false}
                        />
                        { errors.validation_document && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                    </Form.Group>
					{ statusList[ selectedStatusIndex ] && statusList[ selectedStatusIndex ].options && (
						<Form.Group>
							<Form.Label>Estado Adicional <span className="text-primary">*</span></Form.Label>
							<Form.Control
								as="select"
								name="secondary_status"
								ref={register({ required:true })}
							>
								{ statusList[ selectedStatusIndex ].options.map((status,i) => (
									<option key={i}>{status}</option>
								)) }
							</Form.Control>
						</Form.Group>
					)}
					<Form.Group>
						<Form.Label>Comentario <span className="text-primary">*</span></Form.Label>
						<Form.Control 
							as="textarea"
							name="comment"
							ref={register({ required: true })}
						/>
						{ errors.comment && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
					</Form.Group>
					<Button color="primary">Marcar como cerrada en Spring</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}