import React from 'react'
import { useSelector } from 'react-redux'
import { Form, InputGroup } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { storeWhatsappClick } from '../services'
import { handleError } from '../../../helpers'

export default function NewWhatsappClickForm({ quote, reload, handleSubmit, register, errors, reset }) {
	const { details: user } = useSelector(state => state.auth)

	const onSubmit = values => {
		values.user_id = user.id
		values.quote_id = quote.id
		values.channel = 'whatsapp'
		storeWhatsappClick(values)	
			.then(res => {
				message.success(res.data.message)
				reset()
				reload()
				window.open(`https://api.whatsapp.com/send?phone=57${quote.lead.mobile}&text=${encodeURIComponent(values.message)}`)
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<InputGroup>
						<Form.Control 
							name="message"
							as="textarea"
							ref={register({ required:true })}
							placeholder="Escriba aquí..."
							style={{ height: '100px' }}
						/>
						<Button color="primary" type="submit" outline>
							Enviar por Whatsapp
						</Button>
					</InputGroup>
					{ errors.message && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Form>
		</React.Fragment>
	)
}