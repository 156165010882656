import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import QuoteInvoicesList from './QuoteInvoicesList'

export default function QuoteInvoices() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/facturas_cotizaciones" component={QuoteInvoicesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}