import springApi from '../../services/springApi'

export const getWhatsappClicks = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/whatsapp_clicks', {params})
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeWhatsappClick = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/whatsapp_clicks', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateWhatsappClick = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/whatsapp_clicks/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteWhatsappClick = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/whatsapp_clicks/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)