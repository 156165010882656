import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'

import QuoteInvoicesTable from './partials/QuoteInvoicesTable'

import { getUser } from '../Authentication/services'
import { getQuoteInvoices } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function QuoteInvoicesList() {
	const user = useSelector(state => state.auth.details)
	const [quoteInvoices, setQuoteInvoices] = useState(null)
	const [type, setType] = useState('invoice_reference')
	const [value, setValue] = useState('')
	const [scope, setScope] = useState('')
    const [stores, setStores] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
    
    useEffect(()=>{
        !stores && getUser(user.id)
            .then(res => setStores(res.data.data.stores))
            .catch(error => handleError(error))
    }, [stores, user])

	useEffect(()=>{
		!quoteInvoices && stores && getQuoteInvoices({ 
			...pagination,
			[`${user.role === "agent" ? 'filter[quote.user_id]' : 'no_user_scope'}`]: user.id,
            'filter[quote.store_id]': stores.map(s => s.id).join(','),
			[`${scope ? `filter[${scope}]` : 'no_status_scope'}`]: scope ? 1 : 0,
			[`filter[${type}]`]: value,
			include: 'rejectReason,user,quote.store,quote.lead,quote,invoice.customer',
			sort: '-created_at',
		})
			.then(res => {
				setQuoteInvoices(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	})

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className="float-right mt-2">
						<InputGroup>
							<InputGroup.Prepend>
								<Form.Control 
									as="select" 
									className="bg-light"
									value={type}
									onChange={e => setType(e.target.value)}
								>
									<option value="invoice_reference">Buscar por factura</option>
									<option value="quote.user.name">Buscar por asesor</option>
									<option value="quote.store.code">Buscar por codigo de tienda</option>
									<option value="quote.store.name">Buscar por nombre de tienda</option>
								</Form.Control>
							</InputGroup.Prepend>
							<Form.Control 
								placeholder="Escriba aquí" 
								value={value}
								onChange={e => setValue(e.target.value)}
							/>
							<InputGroup.Append>
								<Button color="primary" onClick={()=>setQuoteInvoices(null)}>Buscar</Button>
							</InputGroup.Append>
						</InputGroup>
						<InputGroup className='mt-2 mb-2'>
							<InputGroup.Prepend>
								<Form.Control 
									className="bg-light"
									value="Filtrar por Estado"
									disabled
								/>
							</InputGroup.Prepend>
							<Form.Control 
								as="select"
								value={scope}
								onChange={e => {
									setScope(e.target.value)
									setQuoteInvoices(null)
								}}
							>
								<option value="">:: Ver todos los estados ::</option>
								<option value="pending">En Revisión</option>
								<option value="approved">Válidas</option>
								<option value="rejected">Rechazadas</option>
							</Form.Control>
						</InputGroup>
					</div>
					<CardTitle>Reporte de Cotizaciones Efectivas</CardTitle>
					<CardSubtitle className="mb-3">Listado de facturas asociados a cotizaciones efectivas.</CardSubtitle>
					<QuoteInvoicesTable 
						quoteInvoices={quoteInvoices}
						reload={() => setQuoteInvoices(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}