import React from 'react'
import { Link } from 'react-router-dom'
import { Empty, Table, Tooltip } from 'antd'
import { Badge, Col, Row } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'

export default function QuoteInvoicesTable({ quoteInvoices, pagination, setPagination, reload }) {

	const columns = [
		{
			title: 'Cotización',
			dataIndex: 'quote',
            render: t => <Link to={`/cotizaciones/${t.id}`}>{t.number}</Link>
		},
		{
			title: 'Fecha Cotización',
			dataIndex: 'quote',
			render: t => moment(t.created_at).format('YYYY-MM-DD HH:mm')
		},
		{
			title: 'Factura Asociada',
			dataIndex: 'invoice_reference',
		},
		{
			title: 'Asesor',
			dataIndex: 'user',
            render: t => t ? t.name : <Badge color='dark'>Sistema</Badge>
		},
        {
            title: 'Tienda',
            dataIndex: 'quote',
            render: t => `${t.store.code} - ${t.store.name}`
        },
        {
            title: 'Quien realiza la compra',
            dataIndex: 'validation_relationship_name'
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            render: t => (
                <Tooltip title={t.hint}>
                    <Badge color={t.badge_color}>{t.badge_label}</Badge>
                </Tooltip>
            )
        },
        {
            title: 'Fecha Registro',
            dataIndex: 'created_at',
            render: t => moment(t).format('YYYY-MM-DD HH:mm')
        }
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<Row>
				<Col>
					<p className='mb-2'>
						<b>Fecha Validación: </b>
						{r.validated_at ? moment(r.validated_at).format('YYYY-MM-DD HH:mm') : <em>Sin Validación</em>}
					</p>
					<p className='mb-2'>
						<b>Fecha Rechazo: </b>
						{r.rejected_at ? moment(r.rejected_at).format('YYYY-MM-DD HH:mm') : <em>Sin Rechazo</em>}
					</p>
					<p className='mb-2'>
						<b>Motivo para rechazo: </b>{r.reject_reason ? r.reject_reason.hint : <em>Sin Motivo</em>}
					</p>
				</Col>
				<Col>
					<p className='mb-2'>
						<b>Documento para Validación: </b>{r.validation_document}
					</p>
					<p className='mb-2'>
						<b>Cliente en Cotización: </b>{r.quote.lead.name}
					</p>
					<p className='mb-2'>
						<b>Celular para Validación: </b>{r.validation_mobile}
					</p>
				</Col>
				<Col>
					<p className='mb-2'>
						<b>Cliente en Factura: </b>{r.invoice ? r.invoice.customer.name : <em>Sin Factura Verificada</em>}
					</p>
					<p className='mb-2'>
						<b>Cédula en Factura: </b>{r.invoice ? r.invoice.customer.document : <em>Sin Factura Verificada</em>}
					</p>
					<p className='mb-2'>
						<b>Celular en Factura: </b>{r.invoice ? r.invoice.customer.mobile : <em>Sin Factura Verificada</em>}
					</p>
					<p className='mb-2'>
						<b>Teléfono en Factura: </b>{r.invoice ? r.invoice.customer.phone : <em>Sin Factura Verificada</em>}
					</p>
				</Col>
			</Row>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table
				dataSource={quoteInvoices}
				rowKey={record => record.id}
				columns={columns}
				pagination={false}
				expandable={{ expandedRowRender }}
				locale={{ emptyText: <Empty description="No se encontro registros" /> }}
				size="small"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}	
			/>
		</React.Fragment>
	)
}