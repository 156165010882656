import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, Button } from 'reactstrap'
import { InputGroup, Form } from 'react-bootstrap'
import { getQuotes } from '../Quotes/services'
import { handleError, parsePagination } from '../../helpers'
import QuotesTable from '../Quotes/partials/QuotesTable'

export default function SearchResults() {
	const user = useSelector(state => state.auth.details)
	const [quotes, setQuotes] = useState([])
	const [filterType, setFilterType] = useState('number')
	const [filterValue, setFilterValue] = useState('')
	const [scope, setScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(()=>{
		!quotes && filterValue && getQuotes({
			...pagination,
			'filter[active]': 1,
			[`filter[${filterType}]`]: filterValue,
			"filter[user_id]": scope,
			include: 'lead,user,store,opensCount,profile,whatsappClicksCount',
			sort: '-created_at',
		})
			.then(res => {
				setQuotes(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [quotes, filterType, filterValue, scope, pagination])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<InputGroup className="full-width">
						<InputGroup.Prepend className="mr-2">
							<Form.Control 
								as="select" 
								className="bg-light"
								value={scope}
								onChange={e => setScope(e.target.value)}
							>
								<option value="">Buscar en todas las tiendas</option>
								<option value={user.id}>Buscar solo cotizaciones propías</option>
							</Form.Control>
						</InputGroup.Prepend>
						<InputGroup.Prepend>
							<Form.Control 
								as="select" 
								className="bg-light"
								value={filterType}
								onChange={e => setFilterType(e.target.value)}
							>
								<option value="number">Buscar por número de cotización</option>
								<option value="lead.document">Buscar por cédula del prospecto</option>
								<option value="lead.name">Buscar por nombre del prospecto</option>
								<option value="lead.mobile">Buscar por celular del prospecto</option>
							</Form.Control>
						</InputGroup.Prepend>
						<Form.Control 
							placeholder="Escriba aquí el número parcial o completo"
							value={filterValue}
							onChange={e => setFilterValue(e.target.value)}
						/>
						<InputGroup.Append>
							<Button color="primary" onClick={()=>setQuotes(null)}>Buscar Cotizaciones</Button>
						</InputGroup.Append>
					</InputGroup>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<CardTitle className="mb-3">Resultados de Búsqueda</CardTitle>
					{ quotes && (
						<QuotesTable 
							quotes={quotes} 
							reload={()=>setQuotes(null)} 
							pagination={pagination}
							setPagination={setPagination}
						/>
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}