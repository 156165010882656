import springApi from '../../services/springApi'

export const getQuoteInvoices = params => (
    new Promise((resolve, reject) => {
        springApi()
            .get('/quote_invoices', {params})
                .then(res => resolve(res))
                .catch(error => reject(error))
    })
)

export const storeQuoteInvoice = data => (
    new Promise((resolve, reject) => {
        springApi()
            .post('/quote_invoices', data)
                .then(res => resolve(res))
                .catch(error => reject(error))
    })
)

export const updateQuoteInvoice = (id, data) => (
    new Promise((resolve, reject) => {
        springApi()
            .put(`/quote_invoices/${id}`, data)
                .then(res => resolve(res))
                .catch(error => reject(error))
    })
)