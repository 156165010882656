import React, {useState} from 'react'
import { Button, CardTitle } from 'reactstrap'
import { DatePicker } from 'antd'
import moment from 'moment'

import StoresUsersFilterModal from './StoresUsersFilterModal'
import QuickFiltersModal from './QuickFiltersModal'

const { RangePicker } = DatePicker

export default function TopFiltersBar({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	storesIds,
	setStoresIds,
	usersIds,
	setUsersIds,
	user,
	users,
	stores,
	reload
}) {
	const [showFilters, setShowFilters] = useState(false)	
	const [showQuickFilters, setShowQuickFilters] = useState(false)	
	
	return (
		<React.Fragment>
			<div className="float-right">
				<RangePicker
					ranges={{
						'Hoy': [moment(), moment()],
						'Esta semana': [moment().startOf('week'), moment().endOf('week')],
						'Este mes': [moment().startOf('month'), moment().endOf('month')],
						'Mes pasado': [moment().subtract(1,'months').startOf('month'), moment().subtract(1,'months').endOf('month')],
						'Este año': [moment().startOf('year'), moment().endOf('year')],
					}}
					placeholder={["F. de Inicio", "F. Final"]}
					value={[startDate, endDate]}
					onChange={ values => { 
						if(values.length > 0){
							setStartDate(values[0]); setEndDate(values[1]) 
						}
					}}
					className="mr-3"
				/>
				<Button 
					color="primary" 
					outline 
					className="mr-2" 
					onClick={()=>setShowFilters(true)}
				>
					Tiendas y Asesores
				</Button>
				{ user.role === 'admin' && (
					<Button 
						color="primary" 
						outline 
						className="mr-2" 
						onClick={()=>setShowQuickFilters(true)}
					>
						Filtros Avanzados
					</Button>
				)}
				<Button color="primary" onClick={reload}>Generar Reporte</Button>
			</div>
			<CardTitle>Reporte de Cotizaciones</CardTitle>
			{ showFilters && (
				<StoresUsersFilterModal 
					visible
					onClose={() => setShowFilters(false)}
					storesIds={storesIds}
					setStoresIds={setStoresIds}
					usersIds={usersIds}
					setUsersIds={setUsersIds}
					stores={stores}
					users={users}
					user={user}
					reload={reload}
				/>
			)}
			{ showQuickFilters && (
				<QuickFiltersModal
					visible
					onClose={() => setShowQuickFilters(false)}
					setStoresIds={setStoresIds}
					stores={stores}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}