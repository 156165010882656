import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import NewWhatsappClickForm from '../../../WhatsappClicks/partials/NewWhatsappClickForm'
import WhatsappClickCard from '../../../WhatsappClicks/partials/WhatsappClickCard'
import SuggestionsList from '../../../Suggestions/partials/SuggestionsList'

import { getWhatsappClicks } from '../../../WhatsappClicks/services'
import { handleError } from '../../../../helpers'

export default function QuoteWhatsappClicksWidget({ quote }) {
	const [whatsappClicks, setWhatsappClicks] = useState(null)
    const { handleSubmit, register, errors, reset, setValue } = useForm()

	useEffect(() => {
		!whatsappClicks && getWhatsappClicks({ 'filter[quote_id]': quote.id })
			.then(res => setWhatsappClicks(res.data.data))
			.catch(handleError)
	}, [ whatsappClicks, quote ])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Acciones de Seguimiento</CardTitle>
					<CardSubtitle>Seguimientos registros en la cotización</CardSubtitle>
					<Divider className="mt-2 mb-3" />
					{ quote.published_at ? (
						<React.Fragment>
							{ whatsappClicks ? (
								<React.Fragment>
									{ whatsappClicks.length > 0 ? (
										<React.Fragment>
											{ whatsappClicks.map(whatsappClick => (
												<WhatsappClickCard 
													key={whatsappClick.id} 
													whatsappClick={whatsappClick} 
													reload={() => setWhatsappClicks(null)}
												/>
											)) }
										</React.Fragment>
									) : (
										<Empty description="No tiene seguimientos registrados" imageStyle={{ height:'60px' }} />
									)}
								</React.Fragment>
							) : (
								<Spinner animation='border' size='sm' />
							)}
							<Divider className='mb-3' />
							<NewWhatsappClickForm 
								quote={quote} 
								reload={() => setWhatsappClicks(null)}
								handleSubmit={handleSubmit}
								register={register}
								errors={errors}
								reset={reset}
							/>
							<SuggestionsList 
								quote={quote}
								onSelect={message => setValue('message', message)}
							/>
						</React.Fragment>
					) : (
						<Empty description="Debe publicar la cotización primero" />
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	)
}