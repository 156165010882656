import React from 'react'
import { Alert } from 'reactstrap'
import { message, Popconfirm } from 'antd'

import { deleteWhatsappClick } from '../services'
import { handleError } from '../../../helpers'
import moment from 'moment'

export default function WhatsappClickCard({ whatsappClick, reload }) {
	
	const handleDelete = id => {
		deleteWhatsappClick(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<Alert color="warning" className="p-1 mb-1">
				<p className='mb-0'>
					<b>{whatsappClick.user_name}: </b>{whatsappClick.message}
				</p>
				<div className='float-right'>
					<Popconfirm
						title="Esta acción no se puede revertir"
						okText="Eliminar"
						okButtonProps={{ danger:true }}
						cancelText="Cancelar"
						onConfirm={() => handleDelete(whatsappClick.id)}
					>
						<p className='text-link mb-0'>Eliminar</p>
					</Popconfirm>
				</div>
				<p className='small mb-0'>
					{moment(whatsappClick.created_at).format('Y-MM-DD h:mm A')} | Canal: {whatsappClick.channel}
				</p>
			</Alert>
		</React.Fragment>
	)
}