import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { message } from 'antd'
import moment from 'moment'

import { getSuggestions } from '../services'
import { handleError } from '../../../helpers'

export default function SuggestionsList({ quote, onSelect }) {
    const [suggestions, setSuggestions] = useState(null)

    useEffect(() => {
        !suggestions && getSuggestions({
            'filter[active]': 1,
            'filter[has_opens]': quote.opens.length > 0 ? 1 : 0,
            'filter[days_from_publish]': moment(quote.published_at).diff(moment(), 'days') * -1
        })
            .then(res => setSuggestions(res.data.data))
            .catch(error => handleError(error))
    }, [ suggestions, quote ])
    
    const handleSelect = msg => {
        onSelect(msg)
        message.info('Mensaje copiado para proceder a enviarlo')
    }

    return (
        <React.Fragment>
            <p><strong>Mensajes Sugeridos:</strong></p>
            { suggestions ? (
                <React.Fragment>
                    { suggestions.length > 0 ? (
                        <React.Fragment>
                            { suggestions.map(suggestion => (
                                <SuggestionCard 
                                    key={suggestion.id} 
                                    onClick={() => handleSelect(suggestion.message)}
                                >
                                    <p className='mb-1'><b>{ suggestion.message }</b></p>
                                    <small><em>Sugerido para enviar entre día{" "}
                                        <b>{suggestion.min_days_from_publish}</b> y{" "}
                                        <b>{suggestion.max_days_from_publish}</b> déspues de publicada la cotización.
                                    </em></small>
                                </SuggestionCard>
                            ))}
                        </React.Fragment>
                    ) : (
                        <SuggestionCard className='text-center' disabled>
                            <em>No hay mensajes sugeridos para esta cotización.</em>
                        </SuggestionCard>
                    )}
                </React.Fragment>
            ) : (
                <Spinner animation='border' size="sm" />
            )}
        </React.Fragment>
    )
}

const SuggestionCard = styled.div`
    background-color: #f8f8f8;
    padding: 7px 10px;
    border: 1px dashed #c0c0c0;
    margin-bottom: 5px;
    line-height: 15px;
    &:hover {
        cursor: ${props => !props.disabled ? 'pointer' : 'disabled'};
        opacity: ${props => !props.disabled ? '0.7' : '1.0'};
    }
`