import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, Spinner, Button } from 'reactstrap'
import { Select } from 'antd'

export default function QuickFiltersModal({ 
	visible, 
	onClose,
	setStoresIds, 
	stores, 
	reload
}) {
	const [regions, setRegions] = useState(null)
	const [types, setTypes] = useState(null)
	const [selectedRegions, setSelectedRegions] = useState(null)
	const [selectedTypes, setSelectedTypes] = useState(null)

	useEffect(() => {
		!regions && stores && setRegions( stores.map(store => store.region).filter(onlyUnique) )
		!types && stores && setTypes( stores.map(store => store.type).filter(onlyUnique) )		
	}, [stores, regions, types])

	useEffect(()=>{
		!selectedRegions && regions && setSelectedRegions(regions)
		!selectedTypes && types && setSelectedTypes(types)
	}, [ regions, types, selectedRegions, selectedTypes ])

	const handleSearch = () => {
		const newStores = stores.filter(store => {
			if(selectedRegions.length > 0 && selectedTypes.length > 0 && selectedRegions.includes(store.region) && selectedTypes.includes(store.type) ) return store
			if(selectedRegions.length > 0 && selectedTypes.length === 0 && selectedRegions.includes(store.region)) return store
			if(selectedRegions.length === 0 && selectedTypes.length > 0 && selectedTypes.includes(store.type) ) return store
			if(selectedRegions.length === 0 && selectedTypes.length === 0) return store
			return null
		})

		setStoresIds(newStores.map(store => store.id))

		onClose()
		reload()
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Formulario para filtrar por region y tipo</ModalHeader>
			<ModalBody>
				{ regions ? (
					<div className="mb-3">
						<p className="bold mb-1">Filtrar por Regionales</p>
						<Select
							mode="multiple"
							allowClear
							style={{ width: '100%' }}
							placeholder="Ver todos las regiones"
							value={selectedRegions}
							onChange={value => setSelectedRegions(value)}
						>
							{ regions.map((region, i) => <Select.Option key={i} value={region}>{region}</Select.Option>) }
						</Select>
					</div>
				) : <Spinner size="sm" className="mb-2" />}
				{ types ? (
					<div className="mb-3">
						<p className="bold mb-1">Filtrar por Tipo</p>
						<Select
							mode="multiple"
							allowClear
							style={{ width: '100%' }}
							placeholder="Ver todos los tipos"
							value={selectedTypes}
							onChange={value => setSelectedTypes(value)}
						>
							{ types.map((type, i) => <Select.Option key={i} value={type}>{type}</Select.Option>) }
						</Select>
					</div>
				) : <Spinner size="sm" className="mb-2" />}
				<Button color="primary" onClick={handleSearch}>Filtrar Reporte</Button>
			</ModalBody>
		</Modal>
	)
}

function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
 }